import React from 'react'
import {graphql, PageProps} from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../components/layout'

type DataProps = {
  site: {
    siteMetadata: {
      title: string
    }
  }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Index: React.FC<PageProps<DataProps>> = ({data}) => (
  <Layout>
    <div>
      <div
        className="relative pt-16 pb-32 flex content-center items-center justify-center"
        style={{
          minHeight: '75vh',
        }}>
        <div className="text-center">
          <h1 className="huge">
            Make things wow.
            <br /> But only kinda.
          </h1>
        </div>
      </div>
    </div>
  </Layout>
)

Index.propTypes = {
  data: PropTypes.any,
}

export default Index
